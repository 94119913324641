import(/* webpackMode: "eager", webpackExports: ["PlasmicClientRootProvider"] */ "/vercel/path0/apps/frontend/plasmic-init-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PlasmicComponent"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+loader-nextjs@1.0.418_next@14.2.25_@babel+core@7.26.0_@opentelemetry+api@1.9.0_ba_ewn63slq6sxosdea7a35nszyti/node_modules/@plasmicapp/loader-nextjs/dist/index.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["ExtractPlasmicQueryData"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+nextjs-app-router@1.0.16_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@plasmicapp/nextjs-app-router/dist/index.esm.js");
;
import(/* webpackMode: "eager", webpackExports: ["PlasmicPrepassContext","HeadMetadataContext","Provider"] */ "/vercel/path0/node_modules/.pnpm/@plasmicapp+query@0.1.79_react@18.3.1/node_modules/@plasmicapp/query/dist/index.esm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_bioqopo4dg4p7klhz47znijyqy/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.0_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_bioqopo4dg4p7klhz47znijyqy/node_modules/next/dist/client/script.js");
